<template>
  <div v-if="person_data" class="layout-main ">
    <div class="topsec">
      <!-- <img src="../Files/Sol.svg" alt="" width="18px" height="18px" class="sol"> -->

      <!--i class="pi pi-arrow-left" style="font-size: 2rem"></i-->
    </div>
    <div class="logo-sec">
      <img src="/images/game/Logo-tiquera.svg" alt="" id="logo" />

      <p class="texto-central detalles">DETALLES DEL TICKET</p>
    </div>

    <span class="texto-central division-amarilla">
      <p id="titulo">{{ person_data.ticket_type }}</p>
      <p id="numero-asiento" :style="ticketColor">Número de Asiento</p>
      <h3 id="numero-ticket" :style="ticketColor">{{ person_data.seat_number }}</h3>
    </span>
    <div class="user-info">
      <span>
        <p class="info">Token de brazalete:</p>
        <p class="info">Tipo de entrada:</p>
        <p class="info">Documento de identidad:</p>
        <p class="info">Nombre:</p>
        <p class="info">Apellido:</p>
        <p class="info">Correo Electrónico</p>
        <p class="info">Teléfono</p>
      </span>
      <span>
        <p v-if="person_data.bracelet_token" class="value" style="color:green">{{ person_data.bracelet_token }}</p>
        <p v-else class="value" style="color:red">Por asignar</p>
        <p class="value">{{ person_data.fk_type }}</p>
        <p class="value">{{ person_data.fk_person.dni }}</p>
        <p class="value">{{ person_data.fk_person.first_name }}</p>
        <p class="value">{{ person_data.fk_person.last_name }}</p>
        <p class="value">{{ person_data.fk_person.email }}</p>
        <p class="value">{{ person_data.fk_person.phone_number }}</p>
      </span>
    </div>
    <div class="footer">
      <!-- button id="boton-brazalete">Vincular Brazalete</button -->
      <p class="footer-text">Copyright © 2022 Tiquera.com</p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      access_token: null,
      person_data: null
    };
  },
  created() {
    if (
      !this.$route.query.access_token ||
      this.$route.query.access_token == ""
    ) {
      this.$router.replace("/notfound");
    } else {
      this.access_token = this.$route.query.access_token;
      this.verifyTokenQr(this.access_token).then(() => {
        this.person_data = this.qrdata.qrdata;
      });
    }
  },
  methods: {
    ...mapActions("ticketoffice", ["verifyTokenQr"]),
    ...mapMutations("ticketoffice", ["setVerifyTokenQr"]),
  },
  computed: {
    ...mapState("ticketoffice", ["qrdata"]),
    ticketColor() {
      return {
        "background-color": this.person_data.ticket_color
      }
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/space-age");

#boton-brazalete {
  display: inline-block, flex;
  font-size: 16px;
  align-self: center;
  width: 200px;
  height: 40px;
  background-color: #404040;
  color: #fcd535;
  border-radius: 5px;
  border-color: #fcd535;
  border-width: 1px;
  padding: 10px, 20px, 10px, 20px;
  margin: auto;
  margin-top: 3.5em;
}

* {
  background-color: #404040;
  margin: 0px;
  padding: 0px;
  font-family: sans-serif, "Roboto";
  color: #fff;
}

body {
  display: grid;
  margin: 0px;
}

#logo {
  margin: auto;
}

.sol {
  position: relative;
  left: 20em;
}
#flecha {
  position: absolute;
}
.sol,
#flecha {
  margin: 1em;
}

.pi {
  color: #cbaf02;
}

.logo-sec {
  display: flex;
  flex-direction: column;
}
#titulo {
  font-family: "Space Age", sans-serif;
  font-size: 36px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #cbaf02;
}
.texto-central {
  margin: auto;
}
.detalles {
  margin-top: 2.4em;
  margin-bottom: 2.4em;
}
.division-amarilla {
  text-align: center;
  background-color: #cbaf02;
  display: block;
  margin: 0px;
  margin-bottom: 6px;
}
#numero-asiento,
#numero-ticket {
  margin-top: 6px;
  margin-bottom: 6px;
}
#numero-asiento {
  margin-top: 10px;
}
#numero-ticket {
  margin-bottom: 10px;
}

.user-info {
  margin-top: 1.6em;
  display: flex;
  justify-content: center;
}
.info {
  color: #a1a1a1;
}
.value {
  text-align: right;
  color: #d7d7d7;
}
.info,
.value {
  margin: 0.5em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-text {
  margin-top: 3.6em;
}
.layout-main {
  flex: 1 1 auto;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}
</style>
